import React from "react";
import {BrowserRouter as Router , Routes, Route } from 'react-router-dom'


// Styles 
import '../styles/style.css';
import '../styles/mobile.css';
// Home page 
import Home from "../pages/Home";
// Not Found 
import Error from "../pages/404.jsx";

// navbar 
import Navbar from "./Navbar.jsx";
import Footer from "./Footer.jsx";
import AboutIR from "../components/AboutIR.jsx";
import AboutISKCON from "../components/AboutISKCON.jsx";
import AboutMaharaj from "../components/AboutMaharaj.jsx";

class Routing extends React.PureComponent {
    render() {
        return(
            <Router>
                <Navbar/>
                <Routes>
                    <Route path="/" exact Component={Home}></Route>
                    <Route path="/about-ISKCONARAVADE" Component={AboutIR}></Route>
                    <Route path="/about-ISKCON" Component={AboutISKCON}></Route>
                    <Route path="/about-LokanathSwami" Component={AboutMaharaj}></Route>
                    <Route path="*" Component={Error}></Route>
                </Routes>
                <Footer/>
            </Router>
        )
    }
}

export default Routing;