import React from "react";
import DonationPopup from "./DonationPopup.jsx";
import { Link } from "react-router-dom";

class UpCommingFestival extends React.Component {
  constructor() {
    super();
    this.state = {
      myData: [],
      nextFestival: null,
      showPop: false,
      description: "",
    };
  }

  componentDidMount = () => {
    fetch("https://api1.iskconpandharpur.co/api/festival", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        const filteredData = data
          .filter((item) => !item.title.toLowerCase().includes("disappearance"))
          .map((item) => ({
            ...item,
            formattedDate: this.formatDate(item.date),
          }));
        this.setState({ myData: filteredData }, this.setNextFestival);
      });
  };

  // Function to format the date from "YYYY-MM-DD" to "Month Day" (e.g., "July 19")
  formatDate = (dateStr) => {
    const date = new Date(dateStr);
    return date.toLocaleDateString("en-US", { month: "long", day: "numeric" });
  };

  setNextFestival = () => {
    const today = new Date();
    const { myData } = this.state;

    // Check if there is a featured festival
    const featuredFestival = myData.find((event) => event.isFeatured);

    if (featuredFestival) {
      this.setState({ nextFestival: featuredFestival });
    } else {
      const upcomingEvents = myData.filter(
        (event) => new Date(event.date) >= today
      );

      if (upcomingEvents.length > 0) {
        const nextFestival = upcomingEvents.sort(
          (a, b) => new Date(a.date) - new Date(b.date)
        )[0];
        this.setState({ nextFestival });
      } else {
        const pastEvents = myData.filter(
          (event) => new Date(event.date) < today
        );
        if (pastEvents.length > 0) {
          const closestPastEvent = pastEvents.reduce((closest, event) => {
            const eventDate = new Date(event.date);
            const closestDate = new Date(closest.date);
            return eventDate > closestDate ? event : closest;
          });
          this.setState({ nextFestival: closestPastEvent });
        }
      }
    }
  };
  togglePopup = (description) => {
    this.setState((prevState) => ({
      showPop: !prevState.showPop,
      description: description,
    }));
    console.log(description)
    console.log(this.state.description)
  };
  render() {
    const { nextFestival } = this.state;
    
    return (
      <div className="upcomming-festival-main">
        <DonationPopup
          showPopup={this.state.showPop}
          description={this.state.description}
          togglePopup={this.togglePopup}
          onPaymentSuccess={this.handlePaymentSuccess}
        />
        <h1 className="bordered-heading addtionalp">Upcoming Festival</h1>

        <div>
          {nextFestival && (
            <div className="upcommming-festival" key={nextFestival.id}>
              <div className="festival-image">
                <img className="shadow rounded" src={nextFestival.image} alt="" />
                <p className="festival-date">{nextFestival.formattedDate}</p>
              </div>

              <div className="festival-text">
                <h2>{nextFestival.title}</h2>
                <h6>{nextFestival.subtitle}</h6>
                <p className="pb-5">{nextFestival.description}</p>
                <div className="festivalbutton">
                  <Link to={`/festival/${nextFestival.readmorelink}`}>
                    <button className="read-more1 btn btn-dark">
                      Read More
                    </button>
                  </Link>
                  <button className="shedule-button btn btn-warning">
                    View Schedule
                  </button>
                  <button
                    className="donation-button btn btn-danger"
                    onClick={() => this.togglePopup(nextFestival.title)}
                  >
                    Donate Now
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default UpCommingFestival;
