import React from "react";
import { Link } from "react-router-dom";
import Logo from "../images/logo/LogoWithoutBg.png";

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
      showsubmenu: ""
    };
  }

  toggleSubmenu = (submenu) => {
    this.setState(prevState => ({
      showsubmenu: prevState.showsubmenu === submenu ? "" : submenu
    }));
  };

  closeMenu = () => {
    this.setState({ menuOpen: false });
  };

  render() {
    return (
      <div className="parent-container">
        <div className="navigation container1">
          <div className="nav">
            <ul>
              <li className="doextra">
                <img src={Logo} alt="" />
                <span>ISKCON Aravade</span>
              </li>
              <li>
                <Link to="/" onClick={this.closeMenu}>Home</Link>
              </li>
              <li className="dropdown">
                <Link to="#">About</Link>
                <span className="dropdown-content">
                  <li>
                    <Link to="/about-iskconaravade" onClick={this.closeMenu}>ISKCON Aravade</Link>
                  </li>
                  <li>
                    <Link to="/about-lokanathswami" onClick={this.closeMenu}>Loknath Swami</Link>
                  </li>
                  <li>
                    <Link to="/about-iskcon" onClick={this.closeMenu}>ISKCON</Link>
                  </li>
                </span>
              </li>
              <li className="dropdown">
                <Link to="#">Festival</Link>
                <span className="dropdown-content">
                  <li>
                    <Link to="#" onClick={this.closeMenu}>Ashadhi Ekadashi</Link>
                  </li>
                  <li>
                    <Link to="#" onClick={this.closeMenu}>Janmashtami</Link>
                  </li>
                  <li>
                    <Link to="#" onClick={this.closeMenu}>Radhaashtami</Link>
                  </li>
                  <li>
                    <Link to="#" onClick={this.closeMenu}>Shrila Prabhupada Appearance</Link>
                  </li>
                </span>
              </li>
              <li className="dropdown">
                <Link to="#">Booking</Link>
                <span className="dropdown-content">
                  <li>
                    <Link to="#" onClick={this.closeMenu}>Guest House</Link>
                  </li>
                  <li>
                    <Link to="#" onClick={this.closeMenu}>Vehicle</Link>
                  </li>
                  <li>
                    <Link to="#" onClick={this.closeMenu}>Yatra</Link>
                  </li>
                  
                </span>
              </li>
              <li>
                <Link to="#" onClick={this.closeMenu}>Contact</Link>
              </li>
              <button className="btn btn-danger blinking-button" onClick={this.closeMenu}>Donate</button>
              <div></div>
            </ul>
            <div className="nav-btn">
              <button className="btn btn-warning" onClick={this.closeMenu}>Login</button>
              <button className="btn btn-warning" onClick={this.closeMenu}>SignUp</button>
            </div>
          </div>
        </div>
        <div
          className="mobilemenubtn"
          onClick={() =>
            this.setState({
              menuOpen: !this.state.menuOpen,
            })
          }
        >
          <i className="fa-solid fa-bars"></i>
        </div>
        {this.state.menuOpen && (
          <div>
            <div className="navigationmob container1">
              <div className="nav">
                <ul>
                  <li className="doextra">
                    <img src={Logo} alt="" />
                    <span>ISKCON Aravade</span>
                  </li>
                  <li>
                    <Link to="/" onClick={this.closeMenu}>Home</Link>
                  </li>
                  <li className="dropdown" onClick={() => this.toggleSubmenu("about")}>
                    <Link to="/about-iskconaravade">About</Link>
                    <span className="dropdown-content" style={this.state.showsubmenu === "about" ? { display: "block" } : { display: "none" }}>
                      <li>
                        <Link to="/about-iskconaravade" onClick={this.closeMenu}>ISKCON Aravade</Link>
                      </li>
                      <li>
                        <Link to="/about-lokanathswami" onClick={this.closeMenu}>Loknath Swami</Link>
                      </li>
                      <li>
                        <Link to="/about-iskcon" onClick={this.closeMenu}>ISKCON</Link>
                      </li>
                    </span>
                  </li>
                  <li className="dropdown" onClick={() => this.toggleSubmenu("festival")}>
                    <Link to="#">Festival</Link>
                    <span className="dropdown-content" style={this.state.showsubmenu === "festival" ? { display: "block" } : { display: "none" }}>
                      <li>
                        <Link to="#" onClick={this.closeMenu}>Ashadhi Ekadashi</Link>
                      </li>
                      <li>
                        <Link to="#" onClick={this.closeMenu}>Janmashtami</Link>
                      </li>
                      <li>
                        <Link to="#" onClick={this.closeMenu}>Radhaashtami</Link>
                      </li>
                      <li>
                        <Link to="#" onClick={this.closeMenu}>Shrila Prabhupada Appearance</Link>
                      </li>
                    </span>
                  </li>
                  <li className="dropdown" onClick={() => this.toggleSubmenu("booking")}>
                    <Link to="#">Booking</Link>
                    <span className="dropdown-content" style={this.state.showsubmenu === "booking" ? { display: "block" } : { display: "none" }}>
                      <li>
                        <Link to="#" onClick={this.closeMenu}>Guest House</Link>
                      </li>
                      <li>
                        <Link to="#" onClick={this.closeMenu}>Vehicle</Link>
                      </li>
                      <li>
                        <Link to="#" onClick={this.closeMenu}>Yatra</Link>
                      </li>
                     
                    </span>
                  </li>
                  <li>
                    <Link to="#" onClick={this.closeMenu}>Contact</Link>
                  </li>
                  <button className="btn btn-danger blinking-button" onClick={this.closeMenu}>
                    Donate
                  </button>
                  <div></div>
                </ul>
                <div className="nav-btn">
                  <button className="btn btn-warning" onClick={this.closeMenu}>Login</button>
                  <button className="btn btn-warning" onClick={this.closeMenu}>SignUp</button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Navbar;
