import React from "react";
import Slider from "../components/Slider";
import AboutPrabhupada from "../components/aboutPrabhupada";
import Counter from "../components/counter";
import Donation from "../components/Donation";
import UpcomingEvent from "../components/UpFestival";
import Calendar from "../components/Calender";
import TempleShedule from "../components/TempleTIme";
import Contact from "../components/Contact";
import Pop from "../components/popUp";
class Home extends React.Component { 
    render() {
        return(
            <div className="main-container">
                <Slider/>
                <div className="container">
                <AboutPrabhupada/>
                <Counter />
                <Donation/>
                <UpcomingEvent/>
                <Calendar/>
                <TempleShedule/>
                <Contact/>
                <Pop/>
                </div>
            </div>
        )
    }
}

export default Home;