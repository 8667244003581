import React from "react";
import DonationPopup from "./DonationPopup.jsx";

import Food from "../images/other/fooddonation.jpeg";
class Donation extends React.Component {
  constructor(props) {
    super();
    this.state = {
      nextFestival: null,
      showPop: false,
      description: "",
    };
  }
  togglePopup = (description = "") => {
    this.setState((prevState) => ({
      showPop: !prevState.showPop,
      description: description,
    }));
  };
  render() {
    return (
      <div>
        <DonationPopup
          showPopup={this.state.showPop}
          description={this.state.description}
          togglePopup={this.togglePopup}
          onPaymentSuccess={this.handlePaymentSuccess}
        />
        <h1 className="bordered-heading">Donate</h1>
        <div className="gauseva pt-3">
          <img
            className="shadow"
            src="https://harekrishnamandir.org/uploads/charitable_program/thumbnail2654329.webp"
            alt=""
          />
          <div>
            <h2>Gau Seva - Serve and Protect Cows</h2>
            <span>A Divine Duty to Protect and Care for Cows</span>
            <br />
            <br />
            <div className="text-center">
              <strong>
                गोवत्सपालनं चैव गोविप्राणां च रक्षणम्।
                <br />
                यः करोति नरो नित्यं स याति परमां गतिम्।।
              </strong>
            </div>
            <br />
            <p>
              This shloka highlights the importance of cow protection and care
              in attaining spiritual merit. It underscores the sacred duty of
              protecting and serving cows, which are revered in the Vedic
              tradition.
            </p>
            <p>
              <strong>What Prabhupada Says about - </strong>Srila Prabhupada
              emphasized the significance of cow protection, stating that cows
              are like mothers as they provide essential nourishment through
              their milk. He advocated for the ethical treatment and care of
              cows, considering it a vital aspect of spiritual life.
            </p>
            <p>
              <strong>Importance Of Donation - </strong>Donating to Go Seva is a
              highly meritorious act. It not only supports the well-being of
              cows but also aligns with the Vedic principles of compassion and
              dharma. Your donations help ensure the cows are well-fed,
              sheltered, and cared for, promoting their health and happiness.
            </p>
            <button className="btn btn-danger donationbutton" onClick={() => this.togglePopup("Gau Seva")}>
              Donate Now
            </button>
          </div>
        </div>
        <div className="gauseva">
          <div>
            <h2>Anna Daan - ISKCON ARAVADE</h2>
            <span>Nourishing Hearts, Nourishing Souls</span>
            <br />
            <br />
            <div className="text-center">
              <strong>
                तुरग-शत-सहस्रं गोगणानां च लक्षं
                <br />
                कनक-रजत-पात्रं मेदिनीं सागरान्ताम्‌ |
                <br />
                विमल-कुल-वघूनां कोटि-कन्याश्च दद्यात्‌
                <br />
                नहि नहि सममेतैरन्नदानं प्रधानम् ॥ "
              </strong>
            </div>
            <br />
            <p>
              Giving as gift ten thousand horses, or hundred thousand cows;
              giving the gift of golden or silver vessels; donating all the land
              up till the sea shore; giving away in weddings to deserving men a
              crore of young marriageable women from noble clans -- none of
              these magnificent dAnam-s can equal in importance the feeding of
              the hungry.
            </p>
            <p>
              <strong>What Prabhupada Says about - </strong>Srila Prabhupada
              teaches the importance of feeding the hungry as an act of
              compassion and service. He emphasizes that providing food to those
              in need is a sacred duty and a means of pleasing the Supreme Lord.
            </p>
            <p>
              <strong>Importance Of Donation - </strong>Donating to Anna Daan at
              ISKCON ARAVADE is a way to contribute to a noble cause and
              serve humanity. Your donation helps provide nourishment to the
              needy, fulfill their basic needs, and spread love and kindness in
              the community.
            </p>
            <button className="btn btn-danger donationbutton" onClick={() => this.togglePopup("Anna Daan")}>
              Donate Now
            </button>
          </div>
          <img className="shadow" src={Food} alt="" />
        </div>
      </div>
    );
  }
}

export default Donation;
