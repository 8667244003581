import React from "react";
import handleRazorpay from "../payments/razorpay.js";

class DonationPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: props.amount || "",
      name: "",
      email: "",
      phone: "",
      address: "",
      prasad: false,
      eightyG: false,
      eightyGInput: "",
      showPaymentSuccess: false,
      paymentMessage: "",
      description: props.description || "",
      
    };
    
  }

  componentDidUpdate(prevProps) {
    console.log(this.state.description)
    if (prevProps.amount !== this.props.amount) {
      this.setState({ amount: this.props.amount || "" });
    }
    if (prevProps.description !== this.props.description) {
      this.setState({ description: this.props.description || "" });
    }
  }

  resetState = () => {
    this.setState({
      amount: "",
      name: "",
      email: "",
      phone: "",
      address: "",
      prasad: false,
      eightyG: false,
      eightyGInput: "",
      showPaymentSuccess: false,
      paymentMessage: "",
      description: this.props.description || "",
    });
  };

  togglePopup = () => {
    this.resetState(); // Reset state when closing
    this.props.togglePopup(); // Call the parent togglePopup function
  };

  handlePaymentSuccess = () => {
    this.setState({
      showPaymentSuccess: true,
      paymentMessage: `Hare Krishna, ${this.state.name}`,
    });
    this.props.onPaymentSuccess();
  };

  handlePaymentFailure = () => {
    this.setState({
      showPaymentSuccess: true,
      paymentMessage: "Payment failed! Please try again.",
    });
  };

  handlePayment = () => {
    const {
      amount,
      email,
      name,
      phone,
      address,
      prasad,
      eightyG,
      description,
      eightyGInput,
    } = this.state;
    //https://api1.iskconpandharpur.co/api/donation-razorpay-aravade
    fetch("http://localhost:4041/api/donation-razorpay-aravade", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        amount: amount,
        receipt: email,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.id) {
          handleRazorpay({
            amount: amount,
            orderId: data.id,
            name: name,
            email: email,
            phone: phone,
            address: address,
            eightyG: eightyG,
            prasad: prasad,
            description: description,
            eightyGInput: eightyGInput,
            onPaymentSuccess: this.handlePaymentSuccess,
            onPaymentFailure: this.handlePaymentFailure,
          });
        }
      })
      .catch((error) => {
        console.error("Error during payment:", error);
        this.handlePaymentFailure();
      });
  };

  render() {
    const {
      amount,
      name,
      email,
      phone,
      address,
      prasad,
      eightyG,
      showPaymentSuccess,
      paymentMessage,
    } = this.state;
    const { showPopup } = this.props;

    return (
      showPopup && (
        <div className="donation-popup">
          <div className="donation-popup-content">
            <div className="card p-4 shadow donationpopcontetn">
              <span className="close" onClick={this.togglePopup}>
                &times;
              </span>

              {showPaymentSuccess ? (
                <div>
                  <h2>{paymentMessage}</h2>
                  <p>
                    Thank you for your generous donation! Your payment has been
                    confirmed. Please check your email for further details,
                    including contact information and the attached invoice. Hare
                    Krishna!
                  </p>
                  <button className="btn btn-secondary" onClick={this.togglePopup}>
                    Close
                  </button>
                </div>
              ) : (
                <div>
                  <h2 className="popheading">Make a Donation</h2>
                  <p className="donationfor">{this.state.description}</p>
                  <form>
                    <div className="form-group">
                      <label htmlFor="amount">Amount:</label>
                      <input
                        type="number"
                        id="amount"
                        className="form-control"
                        value={amount}
                        placeholder="Enter Your Amount"
                        onChange={(e) =>
                          this.setState({ amount: e.target.value })
                        }
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="name">Name:</label>
                      <input
                        type="text"
                        id="name"
                        placeholder="Your good name"
                        className="form-control"
                        value={name}
                        onChange={(e) =>
                          this.setState({ name: e.target.value })
                        }
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="phone">Phone:</label>
                      <input
                        type="text"
                        placeholder="Enter phone number without country code"
                        id="phone"
                        className="form-control"
                        value={phone}
                        onChange={(e) =>
                          this.setState({ phone: e.target.value })
                        }
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="email">Email:</label>
                      <input
                        type="text"
                        id="email"
                        className="form-control"
                        value={email}
                        placeholder="Enter your e-mail address"
                        onChange={(e) =>
                          this.setState({ email: e.target.value })
                        }
                        required
                      />
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        id="prasad"
                        className="form-check-input"
                        checked={prasad}
                        onChange={(e) =>
                          this.setState({ prasad: e.target.checked })
                        }
                      />
                      <label htmlFor="prasad" className="form-check-label">
                        Want Prasad?
                      </label>
                    </div>
                    {prasad && (
                      <div className="form-group">
                        <label htmlFor="address">Address:</label>
                        <textarea
                          id="address"
                          className="form-control"
                          value={address}
                          onChange={(e) =>
                            this.setState({ address: e.target.value })
                          }
                          required
                        />
                      </div>
                    )}
                    <div className="form-check">
                      <input
                        type="checkbox"
                        id="eightyG"
                        className="form-check-input"
                        checked={eightyG}
                        onChange={(e) =>
                          this.setState({ eightyG: e.target.checked })
                        }
                      />
                      <label htmlFor="eightyG" className="form-check-label">
                        Want 80G?
                      </label>
                    </div>
                    {eightyG && (
                      <div className="form-group">
                        <label htmlFor="eightyGInput">PAN Number</label>
                        <input
                          type="text"
                          id="eightyGInput"
                          className="form-control"
                          value={this.state.eightyGInput}
                          onChange={(e) =>
                            this.setState({ eightyGInput: e.target.value })
                          }
                          required
                        />
                      </div>
                    )}
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={this.handlePayment}
                    >
                      Donate
                    </button>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
      )
    );
  }
}

export default DonationPopup;
