import React from "react";

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDepartment: "general",
    };
  }

  handleDepartmentChange = (e) => {
    this.setState({
      selectedDepartment: e.target.value,
    });
  };

  render() {
    const { selectedDepartment } = this.state;

    return (
      <div className="container-fluid p-5 contact-main">
        <h1 className="bordered-heading">Contact Us</h1>

        <div className="main-contact-home">
          <div className="contact-details">
            <p className="titlecon">Get Contact Details</p>
            <label htmlFor="department-select">Select Department</label>
            <select
              id="department-select"
              value={selectedDepartment}
              onChange={this.handleDepartmentChange}
              className="custom-select form-control"
            >
              <option value="" disabled>
                Select Department
              </option>
              <option value="general">General</option>
              <option value="guesthouse">Guest House</option>
              <option value="donation">Donation</option>
              <option value="80g">80-G Support</option>
              <option value="technical">Technical Issue</option>
            </select>
            {selectedDepartment === "general" && (
              <div className="department-details">
                <h2>General Inquiries</h2>
                <p>Email: info@iskconaravade.co</p>
                <p>Phone: +91 8888841862</p>
              </div>
            )}
            {selectedDepartment === "guesthouse" && (
              <div className="department-details">
                <h2>Guest House & Bookings</h2>
                <p>Email: guesthouse@iskconaravade.com</p>
                <p>Phone: +91 8888841862</p>
              </div>
            )}
            {selectedDepartment === "technical" && (
              <div className="department-details">
                <h2>Technical Issue </h2>
                <p>Email: help@iskconaravade.com</p>
                <p>Phone: +91 7620075105</p>
              </div>
            )}
            {selectedDepartment === "donation" && (
              <div className="department-details">
                <h2>Donation</h2>
                <p>Email: donate@iskconaravade.com</p>
                <p>Phone: +91  7038400476</p>
              </div>
            )}
            {selectedDepartment === "80g" && (
              <div className="department-details">
                <h2>Donation</h2>
                <p>Email: 80g@iskconaravade.com</p>
                <p>Phone: +91 9419157042</p>
              </div>
            )}
            {/* Other department details */}
          </div>
          <div className="map">
            <p className="titlecon">Map</p>
            <iframe className="mapframe" title="test" width="720" height="600" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=720&amp;height=600&amp;hl=en&amp;q=iskcon%20aravade+(ISKCON%20ARAVADE)&amp;t=k&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.gps.ie/">gps devices</a></iframe>
          </div>
          <div className="contact-form">
            <p className="titlecon">Send Message</p>
            <input
              type="text"
              className="form-control mb-3"
              placeholder="Name"
            />
            <input
              type="email"
              className="form-control mb-3"
              placeholder="Email"
            />
            <input
              type="tel"
              className="form-control mb-3"
              placeholder="Phone"
            />
            <textarea
              className="form-control mb-3"
              rows={4}
              placeholder="Message"
            />
            <button className="btn btn-primary">Send Message</button>
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;
