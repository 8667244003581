import React from "react";

class Prabhupada extends React.Component {
  render() {
    return (
      <div className="container">
        <h1 className="bordered-heading bighead">About Srila Prabhupada</h1>
        <div className="about-prabhupada">
         
          <p>
            <span className="about-prabhupada-title">His Divine Grace A.C. Bhaktivedanta Swami Prabhupada</span> was
            born as Abhay Charan De on September 1, 1896, in Kolkata, India. In
            1965, at the age of 69, he traveled to the United States to fulfill
            the mission of his spiritual master, Bhaktisiddhanta Sarasvati
            Thakura, to spread the teachings of Lord Krishna in the English
            language. 
            <br />
            In 1966, Prabhupada established the International Society
            for Krishna Consciousness in New York City. The movement aimed to
            promote spiritual consciousness through the chanting of the Hare
            Krishna mantra, the distribution of spiritual literature, and the
            practice of bhakti-yoga, the yoga of devotion. 
            <br />
            Under Prabhupada's
            guidance, ISKCON expanded rapidly worldwide, with temples, ashrams,
            and centers established in various countries. He authored numerous
            books on Bhakti Yoga, the Vedic scriptures, and the teachings of
            Lord Krishna. His most well-known work is the multi-volume
            translation and commentary on the Bhagavad Gita and the Srimad
            Bhagavatam.
          </p>
          <img
            src="https://iskconpandharpur.co/static/media/prabhupada.c7c4f037b056233ed2df.png"
            class="img-fluid rounded-top"
            alt=""
          />
        </div>
      </div>
    );
  }
}

export default Prabhupada;
