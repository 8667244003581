import React from 'react';

class Error extends React.Component {
    render() {
        return(


            <div className='container error-page'>
                404 Page Not Found
                <button className='d-block btn btn-warning'>Back to Home</button>
            </div>
        )
    }
}

export default Error;