
const handleRazorpay = async (data) => {
    const options = {
      amount: data.amount * 100,
      key: "rzp_live_fDTn21QUNwBNpA",
      currency: "INR",
      name: "ISKCON-ARAVADE",
      description: data.description,
      // Add your logo if needed
      image: "https://www.iskconcommunications.org/joomlatools-files/docman-images/iskcon_logo.jpg",
      order_id: data.orderId,
      handler: async function (response) {
        const body = {
          ...response,
        };
        try {
          const verifyPayment = await fetch("http://localhost:4041/api/donation-verify", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
          });
          const verified = await verifyPayment.json();
          if (verified.status === "success") {
            // Call the provided callback function to handle payment success
            data.onPaymentSuccess();
          } else {
            data.handlePaymentFailure()
          }
        } catch (err) {
        }
      },
        theme: {
        color: "#9ac5d3", // Blue
        accent: "#9ac5d3", // Orange
        background: "#9ac5d3", // White
      },
      prefill: {
        name: data.name,
        email: data.email,
        contact: `+91${data.phone}`,
        prasad: data.prasad,
        eightyG: data.eightyG,
        address: data.address,
        pan : data.eightyGInput
      },
      notes: {
        name: data.name,
        email: data.email,
        contact: `+91${data.phone}`,
        prasad: data.prasad,
        eightyG: data.eightyG,
        address: data.address,
        description: data.description,
        pan : data.eightyGInput,
        for : "ISKCON-ARAVADE"
      },
    
    };
    const rzp = new window.Razorpay(options);
    rzp.open();
  };
  
  export default handleRazorpay;
  