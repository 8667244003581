import React from "react";

class AboutISKCON extends React.Component {
  render() {
    return (
      <div className="main container">
        <h1 className="bordered-heading">About ISKCON</h1>
        <span className="abtitle">
          International Society for Krishna Consciousness
        </span>
        <div className="d-flex aboutflex">
          <img
            src="https://iskconpandharpur.co/static/media/iskconlogo.54997257993ccaf6d694.png"
            alt=""
          />
          <p>
            International Society for Krishna Consciousness, also popularly
            known as the Hare Krishna movement is a spiritual society founded by
            His Divine Grace A.C. Bhaktivedanta Swami Prabhupada in July 1966 in
            New York. ISKCON belongs to the Gaudiya-Vaishnava sampradaya, a
            monotheistic tradition within Vedic culture. Today ISKCON comprises
            of more than 400 temples, 40 rural communities and over 100
            vegetarian restaurants. It also conducts special projects throughout
            the world, such as “Food for Life”, the only free vegetarian relief
            program in the world.
            <br />
            <br />
            The aim of ISKCON is to acquaint all people of world with universal
            principles of self-realization and God consciousness so that they
            may derive the highest benefit of spiritual understanding, unity and
            peace. The Vedic literature recommend that in the present age of
            Kali-yuga the most effective means of achieving self-realization is
            always hear about, glorify, and remember the all-attractive Supreme
            Lord Sri Krishna. Therefore, it recommends the chanting of the Holy
            Names: Hare Krishna Hare Krishna Krishna Krishna Hare Hare / Hare
            Rama Hare Rama Rama Rama Hare Hare. This sublime chanting puts the
            chanter directly in touch with the Supreme Lord through the sound
            vibration of His Holy Name.
            <br />
            <br />
            ISKCON follows the teachings of the Vedas and Vedic scriptures,
            including the Bhagavad-gita and Srimad Bhagavatam which teach
            Vaishnavism or devotion to God (Krishna) in His Supreme Personal
            aspect of Sri Sri Radha Krishna.
            <br />
            <br />
            These teachings are received through the preceptorial line known as
            the Brahma-Madhav-Gaudiya Vaishnava sampradaya. ISKCON is part of
            the disciplic succession which started with Lord Krishna Himself and
            continued with Srila Vyasadeva, Srila Madhavacharya, Sri Caitanya
            Mahaprabhu and in the present day His Divine Grace A. C.
            Bhaktivedanta Swami Prabhupada and his followers.
            <br />
            <br />
            ISKCON’s teachings are non-sectarian, following the principle of
            sanatana dharma or eternal religion, which denotes the eternal
            activity of all living beings – loving devotional service
            (bhakti-yoga) to Supreme Personality of Godhead.
          </p>
        </div>
        <div >
            <span className="abtitle mt-5">Video</span>
            <iframe width="560" height="560" src="https://www.youtube.com/embed/HN5-LSgvuEg?si=8Wihmm0JZDsJZ_eN" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin"></iframe>
        </div>
      </div>
    );
  }
}

export default AboutISKCON;
