import React from "react";
import Swiper from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/effect-cube";
import { Navigation, Pagination, Scrollbar, Autoplay, EffectCube , EffectCoverflow} from "swiper/modules";

// Images
import first from "../images/slider/1.jpg";
import second from "../images/slider/2.jpg";
import third from "../images/slider/3.jpg";
import forth from "../images/slider/4.jpg";
import five from "../images/slider/5.jpg";
import six from "../images/slider/6.jpg";

class Slider extends React.Component {
  componentDidMount() {
    this.swiper = new Swiper(".swiper", {
      modules: [Navigation, Pagination, Scrollbar, Autoplay, EffectCube, EffectCoverflow],
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      scrollbar: {
        el: ".swiper-scrollbar",
      },
      effect: 'coverflow',
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      coverflowEffect: {
        rotate: 250,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: true,
      },
      slidesPerView: 1,
      spaceBetween: 10,
      loop: true,
    });
  }

  componentWillUnmount() {
    if (this.swiper) {
      this.swiper.destroy(true, true);
    }
  }

  render() {
    return (
      <div className="swiper">
        <div className="swiper-wrapper">
          <div className="swiper-slide mytestcontainer" >
            
            <img src={first} alt="Slide 1" />
            {/* <span className="slidername">Radha Rani</span> */}
          </div>
          <div className="swiper-slide ">
            <img src={second} alt="Slide 2" />
            {/* <span className="slidername">Radha Rani</span> */}
          </div>
          <div className="swiper-slide ">
            <img src={third} alt="Slide 3" />
            {/* <span className="slidername">Radha Rani</span> */}
          </div>
          <div className="swiper-slide">
            <img src={forth} alt="Slide 4" />
            {/* <span className="slidername">Radha Rani</span> */}
          </div>
          <div className="swiper-slide ">
            <img src={five} alt="Slide 5" />
            {/* <span className="slidername">Radha Rani</span> */}
          </div>
          <div className="swiper-slide ">
            <img src={six} alt="Slide 6" />
            {/* <span className="slidername">Radha Rani</span> */}
          </div>
        </div>
        <div className="swiper-pagination"></div>
        <div className="swiper-button-prev"></div>
        <div className="swiper-button-next"></div>
        <div className="swiper-scrollbar"></div>
      </div>
    );
  }
}

export default Slider;
