import React from "react";

class AboutMaharaj extends React.Component {
  render() {
    return (
      <div className="main container">
        <h1 className="bordered-heading">About Lokanath Swami Maharaj
        </h1>
        <span className="abtitle">
        His Holiness Lokanath Swami Maharaj

        </span>
        <div className="d-flex aboutflex">
          <img
            src="https://iskconpandharpur.co/static/media/LNS.96132c4c6af01796afe8.png"
            alt=""
          />
          <p>
          Born in the little village called Aravade, in the year 1949, H.H. Lokanath Swami Maharaj followed the principle of simple living and high thinking since his childhood days. He then went to Bombay for his further studies but destiny had something else in store for him. That was when the unseen merciful hands of Lord Krishna helped the determined young man to become one of Srila Prabhupada’s first Indian disciples.
<br />
<br />
In the year 1971, Srila Prabhupada was traveling to India with his foreign disciples, and they were holding a spiritual festival at Cross Maidan, Bombay. That was when H.H. Lokanath Swami Maharaj’s dream to serve humanity became a reality. He got the inspirational and transcendental chance to meet Srila Prabhupada and from that moment on his fate was sealed. Further, he became an initiated disciple of Srila Prabhupada in 1972 and later sannayasa from Srila Prabhupada in December, 1975, at the age of 26.
<br />
<br />
‘In Conversation with Srila Prabhupada – Contexts and Contemplation’ contains the most cherished recollections of the conversational exchanges between His Holiness Lokanath Swami and Srila Prabhupada, Safely treasured in Maharaj’s memory, these intimate exchanges are now even better preserved and made accessible to all followers of Srila Prabhupada’s movement and undoubtedly, this masterpiece shall keep enlightening future generations too. These books are available in English, Hindi and Marathi also.
<br />
<br />
Adding to the list, this year’s most awaited publication, releasing very soon is Maharaj’s masterpiece ‘Vraja Mandal Darshan’ wherein Maharaj is making us see through his eyes and is also making us live and experience the exceptional Vraja Mandal Parikrama. It is not just a mere book but his feelings, experience, emotions, realization shared and most importantly, Darshan shared. 30 years of Parikrama packed in one book, this is something not to miss.


          </p>
        </div>
        <div>
          <span className="abtitle mt-5">Video</span>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/1StskTrR2uo?si=NMLAxfpH4hwpXhS0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin"></iframe>
        </div>
      </div>
    );
  }
}

export default AboutMaharaj;
