import React from "react";
import './footer.css'
import Logo from '../images/logo/LogoWithoutBg.png'
class Footer extends React.Component {
    render(){

        return(
            <footer>
            <div class="content">
              <div class="top">
                <div class="logo-details">
                  <img src={Logo} alt="" className="logofooter"/>
                  <span class="logo_name">ISKCON Aravade</span>
                </div>
                <div class="media-icons">
                  <a target="_blank" href="https://www.facebook.com/RadhaGopal22?mibextid=ZbWKwL"><i class="fab fa-facebook-f"></i></a>
                  <a target="_blank" href="https://x.com/iskcon_aravade?t=D78F-4gFJSK5ffhajuQJ6g&s=09"><i class="fab fa-twitter"></i></a>
                  <a target="_blank" href="https://www.instagram.com/iskcon_aravade_official?igsh=ZzR3cnV1M2U1czI="><i class="fab fa-instagram"></i></a>
                  <a target="_blank" href="#"><i class="fab fa-linkedin-in"></i></a>
                  <a target="_blank" href="https://www.youtube.com/@iskconaravadeofficial5885"><i class="fab fa-youtube"></i></a>
                </div>
              </div>
              <div class="link-boxes">
                <ul class="box">
                  <li class="link_name">Important Links</li>
                  <li><a href="/">Home</a></li>
                  <li><a href="/contact">Contact us</a></li>
                  <li><a href="/about">Booking</a></li>
                  <li><a href="/festival">Festival</a></li>
                </ul>
                <ul class="box">
                  <li class="link_name">About</li>
                  <li><a href="/iskcon-aravade">ISKCON ARAVADE</a></li>
                  <li><a href="/lokanath-swami-maharaj">Lokanath Swami Maharaj</a></li>
                  <li><a href="/srila-prabhupada">Srila Prabhuda</a></li>
                  <li><a href="/iskcon">ISKCON</a></li>
                </ul>
                <ul class="box">
                  <li class="link_name">Terms and Conditions</li>
                  <li><a href="/payment-policy">Payment Policy</a></li>
                  <li><a href="/refund">Refund</a></li>
                  <li><a href="/80G">80G</a></li>
                  <li><a href="/donation">Donation</a></li>
                </ul>
                <ul class="box input-box">
                  <li class="link_name">Subscribe</li>
                  <li><input type="text" placeholder="Enter your email"/></li>
                  <li><input type="button" value="Subscribe"/></li>
                </ul>
              </div>
            </div>
            <div class="bottom-details">
              <div class="bottom_text">
                <span class="copyright_text">Copyright © 2024 <a href="#">ISKCON ARAVADE</a>All rights reserved</span>
                <span class="policy_terms">
                <span class="copyright_text">Developed By <a href="https://standardsecurity.solutions">Standard Security Solutions</a>❤️</span>
                </span>
              </div>
            </div>
          </footer>
        )
    }
}

export default Footer 