import React from "react";

class Counter extends React.Component {
  render() {
    return (
      <div>
        <div className="d-flex shadow mt-5 p-3 justify-content-around counter ">
          <div>
            <h2 style={{color : "#141e26"}}>150+</h2>
            <span>IYF Devotees</span>
          </div>
          <div>
            <h2 style={{color : "#159ab7"}}>400+</h2>
            <span>Congregation Devotees</span>
          </div>
          <div>
            <h2 style={{color : "#1e5067"}}>16+</h2>
            <span>Brahmachari Devotees</span>
          </div>
        </div>
      </div>
    );
  }
}

export default Counter;
