import React from "react";
import ImageJan from "../images/icon/jamashtami.webp";
import DonationPopup from "./DonationPopup.jsx";

class Pop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPop1: true,
      showPop: false,
      description: ""
    };
  }

  componentDidMount() {
    this.timeout = setTimeout(() => {
      this.setState({ showPop1: false });
    }, 10000);
  }

  togglePopup = (description = "") => {
    this.setState((prevState) => ({
      showPop: !prevState.showPop,
      description: description,
      showPop1: false
    }));
  };

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  handlePop = () => {
    this.setState({ showPop1: false });
    clearTimeout(this.timeout);
  };

  handlePaymentSuccess = () => {
    // Handle payment success logic here
    console.log("Payment successful");
  };

  render() {
    return (
      <>
        <DonationPopup
          showPopup={this.state.showPop}
          description={this.state.description}
          togglePopup={this.togglePopup}
          onPaymentSuccess={this.handlePaymentSuccess}
        />
        {this.state.showPop1 && (
          <div className="loading-pop">
            <div className="showpop">
              <button
                className="btn btn-danger closepopbtn"
                onClick={this.handlePop}
              >
                X
              </button>
              <span className="datepop">26 Aug 2024</span>
              <img className="shadow" src={ImageJan} alt="Janmashtami" />
              <p className="mt-2">
              By observing Sri Krishna Janmashtami, one becomes free from the sinful reactions committed in seven lives. One gets good children, good health, and great wealth…. One will not have to fear enemies and will get sufficient rainfall and never have to suffer from drought. One will not have to fear natural calamities, hellish conditions, snakes, disease, or the attacks of rogues and thieves
              </p>
              <button
                className="btn btn-warning"
                onClick={() => this.togglePopup("Janmashtami")}
              >
                Donate Now
              </button>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default Pop;
