import React, { Component } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './EventCalendar.css';

class EventCalendar extends Component {
    constructor() {
        super();
        this.state = {
            events: [],
            selectedDate: new Date().toDateString(),
            selectedEvents: [],
            activeMonth: new Date().getMonth(),
            activeYear: new Date().getFullYear(),
        };
    }

    componentDidMount() {
        // Get today's date
        const today = new Date().toDateString();
    
        // Fetch events for today's date
        fetch('http://65.0.185.30:4041/api/festival', {
            method: 'GET',
        })
        .then((res) => res.json())
        .then((data) => {
            // Filter events for today
            const todayEvents = data.filter(event => new Date(event.date).toDateString() === today);
            
            // Set state with today's events
            this.setState({ 
                events: data,
                selectedEvents: todayEvents
            });
        });
    }
    

    onDateChange = (date) => {
        console.log(date)
        const selectedEvents = this.state.events.filter(event =>
            new Date(event.date).toDateString() === date.toDateString()
        );
        this.setState({ selectedDate: date, selectedEvents });
    
    }

    onMonthChange = (event) => {
        const [year, month] = event.target.value.split('-');
        const newDate = new Date(this.state.selectedDate.setMonth(month));
        newDate.setFullYear(year);
        this.setState({ activeMonth: month, activeYear: year, selectedDate: newDate });
    }

    // Function to format the date
    formatDate = (dateStr) => {
        const date = new Date(dateStr);
        return date.toLocaleDateString('en-US', { month: 'long', day: 'numeric' });
    };

    tileContent = ({ date, view }) => {
        // Add dots on dates with events
        if (view === 'month') {
            const eventDates = this.state.events.map(event => new Date(event.date).toDateString());
            if (eventDates.includes(date.toDateString())) {
                return <div className="event-indicator"></div>;
            }
        }
        return null;
    };
    render() {
        return (
            <div>
                <h1 className="bordered-heading mt-5">Event Calender</h1>
            <div className="calender-main">
                <div className="calendar-container">
                    <div className="calendar-content">
                        <Calendar
                            onChange={this.onDateChange}
                            value={this.state.selectedDate}
                            tileContent={this.tileContent}
                        />
                        <div className="event-details">
                            {this.state.selectedEvents.length > 0 ? (
                                this.state.selectedEvents.map((event) => (
                                    <div className="event" key={event._id}>
                                        <div className='event-flex1'>
                                            <h2>{event.title}</h2>
                                            <h6>{event.subtitle}</h6>
                                            <span className='event-title'>Description</span>
                                            <p>{event.description}</p>
                                            <br />
                                            <br />
                                            <p>{this.formatDate(event.date)}</p>
                                        </div>
                                        {event.schedule && event.schedule.length > 0 && (
                                            <div className='schedule'>
                                                <h3>Temple Schedule</h3>
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>Time</th>
                                                            <th>Event</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {event.schedule.map((item, index) => (
                                                            <tr key={index}>
                                                                <td>{item.time}</td>
                                                                <td>{item.event}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        )}
                                    </div>
                                ))
                            ) : (
                                <p>No events on this date.</p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            </div>
        );
    }
}

export default EventCalendar;
