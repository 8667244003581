import React from "react";

class AboutIR extends React.Component {
  render() {
    return (
      <div className="main container">
        <h1 className="bordered-heading">About ISKCON ARAVADE</h1>
        <p>
          ISKCON Aravade is a place situated in the midst of many pilgrimages.
          Lord Chaitanya Mahaprabhu travelled by this way towards Pandharpur.
          Also this is <strong>birth place of H.H.Lokanath Swami Maharaja.</strong> In this place
          there is a fresh air, nice hills, and nature’s presence.
        </p>
        <strong> List of pilgrimages are given below:</strong>
        <ol>
          <li>
            North- Shukacharya (Shukdev Goswami’s austerity place) and
            Pandharpur Dham (Place of Vitthal Rukmini).{" "}
          </li>
          <li>
            East- Dandoba (Ram Cave place) and Ghatshila (Ram seated on big
            stone).
          </li>
          <li>
            West- Narsimhapur & Ram Mandir (JwalaNarsimha TirthKshetra),
            Audambar (Lord Dattatrya’s place) and Sri Kshetra Mahuli.
          </li>
          <li>South- Mahalaxmi (Kolhapur).</li>
        </ol>
      </div>
    );
  }
}

export default AboutIR;
