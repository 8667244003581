import React, { Component } from "react";
import OpenImage from "../images/icon/open.png"; // Update the path accordingly
import ClosedImage from "../images/icon/close.png"; // Update the path accordingly
import "./TempleSchedule.css"; // Import the CSS file

class TempleStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: this.getTempleStatus(),
    };
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      this.setState({ status: this.getTempleStatus() });
    }, 60000); // Update status every minute
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getTempleStatus = () => {
    const currentHour = new Date().getHours();
    const currentMinute = new Date().getMinutes();

    // Check if the time is between 4:30 AM and 1:00 PM
    const isMorningOpen =
      (currentHour > 4 || (currentHour === 4 && currentMinute >= 30)) &&
      currentHour < 13;

    // Check if the time is between 3:45 PM and 8:00 PM
    const isAfternoonOpen =
      (currentHour > 15 || (currentHour === 15 && currentMinute >= 45)) &&
      currentHour < 20;

    return isMorningOpen || isAfternoonOpen ? "Open" : "Closed";
  };

  render() {
    const { status } = this.state;
    const imageSrc = status === "Open" ? OpenImage : ClosedImage;

    const schedule = [
      { startTime: "4:30 AM", endTime: "5:00 AM", event: "Mangal Aarti" },
      { startTime: "7:30 AM", endTime: "8:00 AM", event: "Darshan Aarti" },
      { startTime: "8:00 AM", endTime: "9:00 AM", event: "Bhagavatam Katha" },
      { startTime: "12:30 PM", endTime: "1:00 PM", event: "Rajbhog Aarti" },
      { startTime: "1:00 PM", endTime: "3:45 PM", event: "Temple Closed" },
      { startTime: "3:45 PM", endTime: "4:15 PM", event: "Utthan Aarti" },
      { startTime: "6:30 PM", endTime: "7:00 PM", event: "Sandhya Aarti" },
      { startTime: "8:00 PM", endTime: "4:30 AM", event: "Temple Closed" },
    ];

    return (
      <div>
        <h1 className="bordered-heading">Temple Schedule</h1>
        <div className="temple-status-container">
          <div className="status-section">
            <span className="temple-status-label text-center">
              Temple Current Status:{" "}
            </span>
            <img src={imageSrc} alt={status} width={30} />
            <span className="temple-status-text">{status}</span>
          </div>
          <table className="schedule-table">
            <thead>
              <tr>
                <th>Start Time</th>
                <th>End Time</th>
                <th>Event</th>
              </tr>
            </thead>
            <tbody>
              {schedule.map((item, index) => (
                <tr key={index}>
                  <td>{item.startTime}</td>
                  <td>{item.endTime}</td>
                  <td>{item.event}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default TempleStatus;
